.flag-select {
                  position: relative;
                  display: inline-block;
                  vertical-align: inherit;
                  padding-bottom: 5px;
                  text-align: left;
              }
.flag-select__btn {
    padding: 0 8px;
    color: #4d4d4d;
    border: none;
    background: transparent;
}
.flag-select__btn:after, .flag-select__btn[aria-expanded="true"]:after {
     content: " ";
     width: 0;
     height: 0;
     display: inline-block;
     vertical-align: middle;
     margin-left: 5px;
 }
.flag-select__btn:after {
    border-top: 5px solid #4d4d4d;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 0;
}
.flag-select__btn[aria-expanded="true"]:after {
     border-top: 0;
     border-left: 5px solid transparent;
     border-right: 5px solid transparent;
     border-bottom: 5px solid #4d4d4d;
 }
.flag-select__btn:before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
}
.flag-select__option {
    cursor: pointer;
    padding: 0 8px 3px 8px;
    margin: 4px 0;
    white-space: nowrap;
}
.flag-select__options {
    position: absolute;
    z-index: 999999;
    border: 1px solid #bdbbbb;
    border-radius: 3px;
    background: #fff;
    margin-top: 8px;
    padding: 8px 0;
    max-height: 160px;
    overflow: auto;
}
.flag-select__options.to--left {
    right: 10px;
}
.flag-select__option--placeholder {
    height: inherit;
    width: inherit;
    display: inline-block;
    vertical-align: middle;
}
.flag-select__option self:not(--placeholder):hover, .flag-select__option self:not(--placeholder):focus {
  outline: none;
  background: #eaeaea;
}
.flag-select__option.has-label {
    padding: 0 20px 3px 8px;
}
.flag-select__option__label {
    font-size: 1em;
    position: relative;
    padding-left: 9px;
}
.flag-select__option__icon {
    width: 1.3em;
    height: 1.3em;
    position: relative;
    top: 0.3em;
}
.flag-select .filterBox {
    width: 100%;
}
.flag-select .filterBox input {
    width: 90%;
    margin: 0 4%;
}
.flag-select .filterBox input:focus {
    outline: none;
}
.flag-select .hidden {
    display: none;
}
.flag-select .no--focus {
    pointer-events: none;
}
