.toast-notify {
  max-width: 350px;
  overflow: hidden; 
  font-size: .875rem;
  background-clip: padding-box;
  box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
  backdrop-filter: blur(10px);
  opacity: 0;
}

.toast-header-notify {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0,0,0,.05);
}

.toast-body-notify {
  padding: .75rem;
}

.toast-notify:not(:last-child) {
  margin-bottom: 0px;
}

.toast-notify.showing {
  opacity: 1;
}

.toast-notify.show {
  display: block;
  opacity: 1;
}

.toast-notify.hide {
  display: none;
}

.toast-bg-color {
  background-color: white;
  width: 350px !important;
  top: 20px !important;
  right: 20px !important;
  z-index: 1 !important;
  position: fixed !important;
}

.leftAlign {
  float: left;
}

.default {
  background-color: #ffffff;
  border: 0.2px solid #ffffff;
}

.success {
  background-color: #d0f1d5;
  border: 0.2px solid #d0f1d5;
  color: darkgreen;
}

.error {
  background-color: #fbd4d3;
  border: 0.2px solid #fbd4d3;
  color: darkred;
}

.warn {
  background-color: #fff0b4;
  border: 0.2px solid #fff0b4;
  color: #a27b4a;
}

.info {
  background-color: #d5edf5;
  border: 0.2px solid #d5edf5;
  color: darkcyan;
}

#closeButtonNotify {
  position: absolute;
  right: 3px;
  float: right;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  /* color: #000; */
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

#mainToast {
  z-index: 1051 !important;
}